import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import SiteHeader from './layout/siteheader/siteheader';

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;
window.krpanoApi = false;

const handleUsercentrics = () => {
    const maxTries = 100;
    let currentTry = 0;

    const addStyleTag = ($host) => {
        const $shadowRoot = $host.shadowRoot;
        $host.style.position = 'relative';
        $host.style.zIndex = '1';
        const $styleTag = document.createElement('style');
        const styleInformation = '.dpMfLB { border: 1px solid #de2e36; border-radius: 10px; } .dpMfLB:hover { background-color: #f9f1f1; } .hgOLgN { border-radius: 10px; }';
        $styleTag.innerHTML = styleInformation;

        $shadowRoot.appendChild($styleTag);
    };

    const userCentricsInterval = window.setInterval(() => {
        const $usercentrics = document.getElementById('usercentrics-root');
        if ($usercentrics || currentTry === maxTries) {
            clearInterval(userCentricsInterval);
            if ($usercentrics) {
                addStyleTag($usercentrics);
            }
        }
        currentTry = currentTry + 1;
    }, 100);
};

const initContent = ($content) => {
    const $$anchor = $content.querySelectorAll('a[href*="#"]');
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];

        $anchor.addEventListener('click', (e) => {
            let baseUrl = $anchor.getAttribute('href').split('#')[0];
            const targetId = $anchor.getAttribute('href').split('#')[1];
            const $target = document.getElementById(targetId);
            const currentUrl = window.location.href;

            if (baseUrl === '') {
                baseUrl = currentUrl;
            }

            if (baseUrl === currentUrl && $target !== null) {
                const anchorDimensions = getOffset($target);
                const anchorDimensionsTop = anchorDimensions.top;

                smoothScrollTo(0, anchorDimensionsTop, window.OPTIONS.speed);
                e.preventDefault();
            }
        });
    }

    loadLazyImages();
};

domready(function () {
    window.OPTIONS = {
        speed: 500,
        breakpoints: {
            s: 320,
            m: 768,
            l: 1024,
            xl: 1280,
            xxl: 1440,
            xxxl: 1920
        }
    };

    const initApplication = () => {
        console.log('start app');
        initContent(document);

        handleUsercentrics();

        window.eventScroller.init();
        window.resizeHandler.init();

        const $siteheader = document.querySelector('[data-siteheader="root"]');
        if ($siteheader) {
            const siteheaderAPI = new SiteHeader($siteheader);
            $siteheader.API = siteheaderAPI;
        }

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        document.body.classList.add('page--loaded');
        window.addEventListener('beforeunload', () => {
            document.body.classList.remove('page--loaded');
        });
    };

    initApplication();
});
