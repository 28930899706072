import './siteheader.scss';

class SiteHeader {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-siteheader',
            onscrollAttr: 'data-siteheader-sticky' // Attribute for sticky state when scrolled
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);

        // Elements
        this.$root = $element;

        // Options
        this.eventScroller = window.eventScroller; // Event handler for scroll events
        this.currentScrollPosition = window.scrollY; // Track the current scroll position

        this.initialize();
    }

    initialize () {
        this.checkPagePosition(); // Check the page position initially
        this.eventScroller.customFunctions.push(() => {
            this.checkPagePosition(); // Re-check the page position on scroll
        });
    }

    checkPagePosition () {
        if (window.scrollY > 56) {
            this.$root.setAttribute(this.settings.onscrollAttr, ''); // Add sticky attribute if scrolled beyond 56px
        } else {
            this.$root.removeAttribute(this.settings.onscrollAttr); // Remove sticky attribute if scrolled less than 56px
        }
    }
}

export default SiteHeader;
